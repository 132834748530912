import React from 'react';

import { Divider, Flex, useBreakpointValue } from '@chakra-ui/react';

import mentees from '@assets/mentees.svg';
import sessionsBooked from '@assets/sessionsBooked.svg';
import wallet from '@assets/wallet.svg';

import { MenterInfoCard } from '../cards/MenterInfoCard';

export const MentorIcon = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Flex
            my={{ base: 2, md: 20 }}
            mx={{ base: 0, md: -10, lg: -20 }}
            bg="headerBg"
            align="center"
            justifyContent="center"
            gap={12}
            p={16}
            direction={{ base: 'column', md: 'row' }}
        >
            <MenterInfoCard
                imageSrc={wallet}
                heading="80%"
                text="<b>of learning happens outside formal education</b><br />We empower you to access valuable mentorship for practical learning"
            />
            {!isMobile && <Divider orientation="vertical" height="60" />}
            <MenterInfoCard
                imageSrc={sessionsBooked}
                heading="67%"
                text="<b>of businesses report improved productivity through mentorship</b><br />Get expert guidance to work smarter, not harder"
            />
            {!isMobile && <Divider orientation="vertical" height="60" />}
            <MenterInfoCard
                imageSrc={mentees}
                heading="70%"
                text="<b>of professionals with mentors feel more confident in their careers</b><br />Join us to gain clarity and confidence"
            />
        </Flex>
    );
};
